*{
  text-align: center;
}

#root > div > div > div > div:nth-child(1) {
 
  animation: blink .5s linear infinite alternate;
}

@keyframes blink {
  0% {
    background-color: green;
  }
  100% {
    background-color: white;
  }
}


.row > div:nth-last-child(-n+12) {
  opacity: 0.4;
}